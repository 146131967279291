/** @jsx jsx */
import { css, jsx, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import DefaultMainMenu from "@whitespace/gatsby-theme-wordpress-basic/src/wsui/components/HeaderMainMenu.jsx";
import {
  Clickable,
  Icon,
  PageGrid,
  PageGridItem,
  Link,
  handleComponentsProp,
  useThemeProps,
} from "@wsui/base";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import HeaderHelpMenu from "../../../../HeaderHelpMenu.jsx";
import DefaultLogo from "../../../../HeaderLogo.jsx";
import logoCmm from "../../../../images/logo-evergreen.svg";
import logoKarolinska from "../../../../images/logo-karolinska-universitetssjukhuset.svg";
import logoKi from "../../../../images/logo-ki.svg";
import logoCmmWhite from "../../../../images/logo-white.svg";
import HeaderSearchField from "../../../../wsui/components/HeaderSearchField.jsx";

import DefaultHamburgerMenu from "./HeaderHamburgerMenu.jsx";

const DefaultHamburgerMenuToggle = styled(Clickable)``;

export default function Header(props) {
  const { t } = useTranslation();
  const theme = useTheme();

  props = useThemeProps({
    props,
    name: "Header",
  });

  let {
    color,
    components,
    disableHamburgerMenu = false,
    border = "border",
    paddingBlock = 4,
    boxShadow = "0 0.125rem 0.25rem rgba(0, 0, 0, 0.075)",
    ...restProps
  } = props;

  let { HamburgerMenu, HamburgerMenuToggle, Logo, MainMenu } =
    handleComponentsProp(components, {
      HamburgerMenu: DefaultHamburgerMenu,
      HamburgerMenuToggle: DefaultHamburgerMenuToggle,
      Logo: DefaultLogo,
      MainMenu: DefaultMainMenu,
      HeaderHelpMenu,
    });

  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  useEffect(() => {
    document.body.style.overflow = hamburgerOpen ? "hidden" : "";
  }, [hamburgerOpen]);

  const logos = [
    { logo: logoCmm, link: "/", alt: "CMM" },
    { logo: logoKi, link: "https://ki.se/", alt: "Karolinska institutet" },
    {
      logo: logoKarolinska,
      link: "https://www.karolinska.se/",
      alt: "Karolinska universitetssjukhuset",
    },
  ];

  return (
    <header {...restProps}>
      {HeaderHelpMenu && (
        <HeaderHelpMenu
          css={css`
            @media (max-width: 640px) {
              display: none;
            }
          `}
        />
      )}
      <PageGrid
        css={css`
          background: white;
          color: ${theme.getColor([color, "text"])};
          box-shadow: ${boxShadow};

          @media (max-width: 641px) {
            background: ${theme.getColor("evergreen")};
          }
        `}
      >
        <PageGridItem colspan={12}>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              padding-block: ${theme.getLength(paddingBlock)};
              align-items: center;
            `}
          >
            {/* TODO: Implement header content with WSUI components */}
            {/* <SkipTo />
             */}
            <Logo
              logo={logoCmmWhite}
              link={"/"}
              css={css`
                @media (min-width: 641px) {
                  display: none;
                }

                @media (max-width: 680px) {
                  img {
                    max-width: 151px;
                  }
                }
              `}
            />
            <div
              css={css`
                display: flex;
                flex-wrap: nowrap;
              `}
            >
              {logos ? (
                logos.map((logo, i) => (
                  <Logo
                    key={i}
                    logo={logo.logo}
                    link={logo.link}
                    alt={logo.alt}
                    css={css`
                      display: flex;
                      align-items: center;

                      &:not(:first-of-type) {
                        margin-left: 2.5rem;
                      }
                      &:nth-of-type(1) {
                        img {
                          height: 43px;
                        }
                      }
                      &:nth-of-type(2) {
                        img {
                          height: 51px;
                        }
                      }
                      &:nth-of-type(3) {
                        img {
                          height: 38px;
                        }
                      }
                      @media (max-width: 640px) {
                        display: none;
                      }
                      @media (max-width: 960px) {
                        &:not(:first-of-type) {
                          display: none;
                        }
                      }
                    `}
                  />
                ))
              ) : (
                <Logo
                  link="/"
                  css={css`
                    grid-area: logo;
                  `}
                />
              )}
            </div>
            <MainMenu
              css={css`
                align-self: stretch;
                display: grid;
                align-content: center;
                grid-template-columns: minmax(0, 1fr);
                @media (min-width: 480px) {
                  @supports not
                    selector(:has(.wsui-inline-list.wsui-is-content-visible)) {
                    border-left: 2px solid ${theme.getColor(border)};
                    padding-left: ${theme.getLength([4, 8])};
                  }
                  &:has(.wsui-inline-list.wsui-is-content-visible) {
                    border-left: 2px solid ${theme.getColor(border)};
                    padding-left: ${theme.getLength([4, 14])};
                  }
                }
              `}
            />
            <div
              css={css`
                display: flex;
                gap: ${theme.getLength(5)};
                align-items: center;
              `}
            >
              <HeaderSearchField
                css={css`
                  @media (max-width: 480px) {
                    display: none;
                  }
                `}
              />
              <Link
                uri="/search"
                css={css`
                  grid-area: search;
                  display: flex;
                  flex-flow: column;
                  padding: 1rem 1.25rem;
                  background-color: white;
                  border-radius: 0.4rem;
                  text-decoration: none;
                  @media (min-width: 481px) {
                    display: none;
                  }
                `}
              >
                <Icon
                  name="search"
                  size="6"
                  color={theme.getColor("evergreen")}
                />
                <span
                  css={css`
                    font-size: ${theme.getLength(3.25)};
                    line-height: ${theme.getLength(5)};
                    font-weight: bold;
                    @media (max-width: 680px) {
                      display: none;
                    }
                  `}
                >
                  Sök
                </span>
              </Link>
              {!disableHamburgerMenu && (
                <HamburgerMenuToggle
                  onClick={() => {
                    setHamburgerOpen(true);
                  }}
                  aria-label={t("menu")}
                  aria-expanded={String(hamburgerOpen)}
                  aria-controls={"header-hamburger-menu"}
                  css={css`
                    grid-area: flyout;
                    cursor: pointer;
                    border: none;
                    border-radius: 0.4rem;
                  `}
                >
                  <Icon
                    name="menu"
                    size="6"
                    css={css`
                      background-color: white;
                      @media (max-width: 641px) {
                        background-color: ${theme.getColor("evergreen")};
                      }
                    `}
                  />
                </HamburgerMenuToggle>
              )}
            </div>
          </div>
        </PageGridItem>
      </PageGrid>
      {!disableHamburgerMenu && (
        <HamburgerMenu
          open={hamburgerOpen}
          onClose={() => setHamburgerOpen(false)}
          id={"header-hamburger-menu"}
        />
      )}
    </header>
  );
}
